import React from 'react';
import Helmet from 'react-helmet';
import Layout from '../components/Layout';
import { Container } from '../components/Theme';


const Success = () => (
  <Layout>
    <Helmet
      title='Message Sent - Metro Communications'
      meta={[
        { name: 'description', content: `` },
        { name: 'keywords', content: `` },
      ]}
    />
    <Container>
      <h1>Message Sent</h1>
      <p>Thank you for getting in touch.</p>
      <p>Your details have been emailed to us and we shall be in touch shortly.</p>
    </Container>
  </Layout>
);

export default Success;